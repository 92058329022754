import React from 'react';
import { Context } from '../../../Context/AuthContext';
import api from '../../../services/api';
import Loading from '../../Loading';
import { useState, useEffect } from 'react';
import { FiEdit, FiTrash2, FiPrinter } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import { Input, Dropdown, Statistic, Card } from 'semantic-ui-react'
import CountUp from 'react-countup';

import Chart from "react-google-charts";
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import br from 'date-fns/locale/pt-BR';

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Menu } from '../../../global.js';

export default function DashboardPlano() {

	const { aviso, Observer } = React.useContext(Context);
	const host = require('../../../config.json')
	const [carregando, setCarregando] = useState(false);
	const [filtro, setFiltro] = useState('')
	const [filtro2, setFiltro2] = useState('')
	const [filtro3, setFiltro3] = useState('')
	const [name, setName] = useState('')
	const [empresa, setEmpresa] = useState(-1);
	const [estabelecimento, setEstabelecimento] = useState([]);
	const [options, setOptions] = useState([])
	const [options2, setOptions2] = useState([])
	const [optionsAux2, setOptionsAux2] = useState([])
	const [opt, setOpt] = useState()
	const [opt2, setOpt2] = useState()
	const [chartData, setChartData] = useState([["Risco", "Porcentagem"], ["", ""]])
	const [chartData2, setChartData2] = useState([["Risco", "Porcentagem"], ["", ""]])

	const [planos, setPlanos] = useState([])
	const [foundPlanos, setFoundPlanos] = useState([])

	const [optionsSearch, setOptionsSearch] = useState([])
	const [optionsSearch2, setOptionsSearch2] = useState([])
	const [optionsSearch3, setOptionsSearch3] = useState([])

	const [inicio, setInicio] = useState('')
	const [fim, setFim] = useState('')

	const [totalP, setTotalP] = useState(0)

	const [pni, setPni] = useState(0)
	const [pea, setPea] = useState(0)
	const [pa, setPa] = useState(0)
	const [pc, setPc] = useState(0)

	const [gatilho, _setGatilho] = useState(true)

	registerLocale('pt-BR', br)

	const ref = React.useRef(gatilho);
	const setGatilho = data => {
		ref.current = data;
		_setGatilho(data);
	};

	const [count, setCount] = useState({
		prev: 0,
		next: 10
	})

	const [hasMore, setHasMore] = useState(true);
	const [current, setCurrent] = useState(foundPlanos.slice(count.prev, count.next))

	const getMoreData = () => {

		if (current.length === foundPlanos.length) {
			setHasMore(false);
			return;
		}

		if (current.length > 0) {
			setTimeout(() => {
				setCurrent(current.concat(foundPlanos.slice(count.prev + 10, count.next + 10)))
			}, 500)
			setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
		}

	}


	useEffect(() => {

		function inicial() {

			var keyword = name

			if (empresa !== -1 && filtro !== '') {


				if (sessionStorage.getItem("Gatilho") == "true") {
					handleChange2("", { value: estabelecimento })
					sessionStorage.removeItem('dashboardPlano')
				}

				sessionStorage.removeItem("Gatilho")

				setFoundPlanos([])
				setCurrent([].slice(0, 10))

				var planosData
				var planosF
				var planosFiltrados

				var ini = new Date(inicio)
				var fi = new Date(fim)

				if ((inicio !== null && inicio !== '') && fim < inicio && (fim !== null && fim !== '')) {
					aviso("warning", "Data final menor que a inicial ")
					planosData = planos
				} else {

					if ((inicio !== null && inicio !== '') && (fim == null || fim == '')) {
						planosData = planos.filter(item => new Date(item.quando) >= ini)
					} else if ((fim !== null && fim !== '') && (inicio == null || inicio == '')) {
						planosData = planos.filter(item => new Date(item.quando) <= fi)
					} else if ((inicio !== null && inicio !== '') && (fim !== null && fim !== '')) {
						planosData = planos.filter(item => (new Date(item.quando) <= fi && new Date(item.quando) >= ini))
					} else {
						planosData = planos
					}

				}

				setTotalP(planosData.length)
				updateChart(planosData)
				updateStats(planosData)

				if (filtro3 == "Risco inaceitável") {
					planosFiltrados = planosData.filter(item => item.resultado > 19)
				} else if (filtro3 == "Risco aceitável") {
					planosFiltrados = planosData.filter(item => item.resultado < 5)
				} else if (filtro3 == "Risco incerto") {
					planosFiltrados = planosData.filter(item => item.resultado > 11 && item.resultado < 20)
				} else if (filtro3 == "Todos") {
					planosFiltrados = planosData
				} else {
					planosFiltrados = planosData.filter(item => item.resultado > 4 && item.resultado < 12)
				}

				if (filtro == "Concluído") {
					if (filtro2 == "Todos") {
						planosF = planosFiltrados.filter(item => item.concluido == true)
					} else if (filtro2 == "No prazo") {
						planosF = planosFiltrados.filter(item => new Date(item.quando) >= new Date(item.data_concluido) && item.concluido == true)
					} else {
						planosF = planosFiltrados.filter(item => new Date(item.quando) < new Date(item.data_concluido) && item.concluido == true)
					}
				} else if (filtro == "Todos") {
					planosF = planosFiltrados
				} else if (filtro == "Em andamento") {
					planosF = planosFiltrados.filter(item => item.iniciado == true && item.concluido == false && new Date(item.quando) >= new Date())
				} else if (filtro == "Não iniciado") {
					planosF = planosFiltrados.filter(item => item.iniciado == false && new Date(item.inicio) >= new Date())
				} else {
					planosF = planosFiltrados.filter(item => (item.iniciado == true && item.concluido == false && new Date(item.quando) < new Date()) || (item.iniciado == false && item.concluido == false && new Date(item.inicio) < new Date()))
				}



				if (keyword !== '') {

					const results = planosF.filter((plano) => {
						if (plano.t_nome !== undefined) {
							return (plano.o_que.toLowerCase().includes(keyword.toLowerCase()) || plano.quem.toLowerCase().includes(keyword.toLowerCase()) || plano.t_nome.toLowerCase().includes(keyword.toLowerCase()) || plano.r_nome.toLowerCase().includes(keyword.toLowerCase()));
						} else {
							return (plano.o_que.toLowerCase().includes(keyword.toLowerCase()) || plano.quem.toLowerCase().includes(keyword.toLowerCase()));
						}

					});

					setHasMore(true)
					setFoundPlanos(results);
					setCount({
						prev: 0,
						next: 10
					})
					setTimeout(() => {
						setCurrent(results.slice(0, 10))
					}, 0)


				} else {

					setHasMore(true)
					setFoundPlanos(planosF);
					setCount({
						prev: 0,
						next: 10
					})
					setTimeout(() => {
						setCurrent(planosF.slice(0, 10))
					}, 0)


				}

			}

		}

		inicial()


	}, [name, filtro, filtro2, filtro3, gatilho, planos, inicio, fim])

	useEffect(() => {

		async function inicial() {

			setCarregando(true)

			try {

				if (empresa != -1) {

					setFoundPlanos([])
					setCurrent([].slice(0, 10))

					setFiltro3("Todos")
					setOptionsSearch3(["Todos", "Risco aceitável", "Risco inaceitável", "Risco em nível de ação", "Risco incerto"])

					setFiltro2("Todos")
					setOptionsSearch2(["Todos", "No prazo", "Fora do prazo"])

					setFiltro("Todos")
					setOptionsSearch(['Todos', 'Atrasado', 'Não iniciado', 'Em andamento', 'Concluído'])

					setOpt({
						is3D: true,
						pieStartAngle: 50,
						backgroundColor: {
							fill: "none",
						},
						legend: { position: "right" },
						chartArea: { width: "95%", height: "80%" },
						slices: {
							0: { color: '#d02121' },
							1: { color: '#fabe33' },
							2: { color: 'blue' },
							3: { color: 'green' }
						},
					})

					setOpt2({
						is3D: true,
						pieStartAngle: 50,
						backgroundColor: {
							fill: "none",
						},
						legend: { position: "right" },
						chartArea: { width: "95%", height: "80%" },
						slices: {
							0: { color: 'blue' },
							1: { color: 'orange' }
						},
					})
				}
			} catch (error) {
				console.log(error)
			} finally {
				setCarregando(false)
			}
		}

		inicial()

	}, [empresa])

	useEffect(() => {

		async function inicial() {

			setCarregando(true)

			try {

				var op = []
				await Promise.all([
					api.get('empresa')
				]).then(response => {
					response[0].data.resultado.forEach((element, i) => {
						op.push({ key: i, value: element.id, text: element.razao_social })
					});
					setOptions(op)
				})

			} catch (error) {
				console.log(error)
			} finally {
				setCarregando(false)
			}
		}

		inicial()
		window.addEventListener('focus', onfocus);

		// cleanup this component
		return () => {
			window.removeEventListener('focus', onfocus);
		};

	}, [])

	const onfocus = () => {
		setGatilho(!ref.current)
		sessionStorage.setItem("Gatilho", "true")
	}

	const updateChart = (planos) => {

		var pni2 = planos.filter(item => item.iniciado == false && new Date(item.inicio) >= new Date()).length
		var pea2 = planos.filter(item => item.iniciado == true && item.concluido == false && new Date(item.quando) >= new Date()).length
		var pa2 = planos.filter(item => (item.iniciado == true && item.concluido == false && new Date(item.quando) < new Date()) || (item.iniciado == false && item.concluido == false && new Date(item.inicio) < new Date())).length
		var pc2 = planos.filter(item => item.concluido == true).length
		setChartData([["Planos", "Porcentagem"], ["Planos atrasados", pa2], ["Planos não iniciados", pni2], ["Planos em andamento", pea2], ["Planos concluídos", pc2]])


		var noprazo = planos.filter(item => new Date(item.quando) >= new Date(item.data_concluido) && item.concluido == true).length
		var foraprazo = planos.filter(item => new Date(item.quando) < new Date(item.data_concluido) && item.concluido == true).length

		setChartData2([["Riscos", "Porcentagem"], ["Concluído no prazo", noprazo], ["Concluído fora do prazo", foraprazo]])

	}

	const updateStats = (planos) => {

		setPa(planos.filter(item => (item.iniciado == true && item.concluido == false && new Date(item.quando) < new Date()) || (item.iniciado == false && item.concluido == false && new Date(item.inicio) < new Date())).length)
		setPc(planos.filter(item => item.concluido == true).length)
		setPea(planos.filter(item => item.iniciado == true && item.concluido == false && new Date(item.quando) >= new Date()).length)
		setPni(planos.filter(item => item.iniciado == false && new Date(item.inicio) >= new Date()).length)

	}

	const handleChange = async (e, { value }) => {

		setCarregando(true)

		setFoundPlanos([])
		setCurrent([].slice(0, 10))

		setPlanos([])
		setTotalP(0)
		setEstabelecimento([])
		setEmpresa(value);

		var op = []

		op.push({ key: -1, value: -1, text: "Todas organizações" })

		await api.get(`estabelecimento?empresa_id=${value}`).then(response => {
			response.data.resultado.forEach((element, i) => {
				op.push({ key: i, value: element.id, text: element.nome })
			});
		})

		setOptions2(op)
		setOptionsAux2(op)

		setCarregando(false)
	}


	const handleChange2 = async (e, { value }) => {

		setEstabelecimento(value);

		setFoundPlanos([])
		setCurrent([].slice(0, 10))

		setCarregando(true)

		var response = await api.get('dashboardP?empresa_id=' + empresa)
		var plano_empresa

		if (value.includes(-1)) {
			setOptionsAux2([{ key: -1, value: -1, text: "Todas organizações" }])
			plano_empresa = response.data.resultado
		} else {
			var top = options2.filter(item => optionsAux2.includes(item))
			if (top.length !== optionsAux2.length) {
				setOptionsAux2(options2)
			}
			plano_empresa = response.data.resultado.filter(item => value.includes(item.es_id))
		}

		setPlanos(plano_empresa)
		setTotalP(plano_empresa.length)

		setCarregando(false)

	}

	const handler = function (e) {

		var parts = e.targetID.split("#")[1];

		if (parts == '0') {
			setFiltro("Atrasado")
			setFiltro3('Todos')
		} else if (parts == '1') {
			setFiltro("Não iniciado")
			setFiltro3('Todos')
		} else if (parts == '2') {
			setFiltro("Em andamento")
			setFiltro3('Todos')
		} else {
			setFiltro("Concluído")
			setFiltro2("Todos")
			setFiltro3('Todos')
		}

	};

	const handler2 = function (e) {

		var parts = e.targetID.split("#")[1];

		if (parts == '0') {
			setFiltro("Concluído")
			setFiltro2("No prazo")
		} else if (parts == '1') {
			setFiltro("Concluído")
			setFiltro2("Fora do prazo")
		}

	};

	const PieChart = React.useMemo(() =>
	(
		<Chart
			chartType="PieChart"
			width={'100%'}
			height={'240px'}
			data={chartData}
			options={opt}
			legendToggle
			chartEvents={[
				{
					eventName: "ready",
					callback: ({ chartWrapper, google }) => {
						const chart = chartWrapper.getChart();
						google.visualization.events.addListener(
							chart,
							"click",
							handler
						);
					}
				}
			]}
		/>
	), [chartData, opt])

	const PieChartPrazo = React.useMemo(() =>
	(
		<Chart
			chartType="PieChart"
			width={'100%'}
			height={'240px'}
			data={chartData2}
			options={opt2}
			legendToggle
			chartEvents={[
				{
					eventName: "ready",
					callback: ({ chartWrapper, google }) => {
						const chart = chartWrapper.getChart();
						google.visualization.events.addListener(
							chart,
							"click",
							handler2
						);
					}
				}
			]}
		/>
	), [chartData2, opt2])

	const DropEmpresas = React.useMemo(() =>
	(
		<Dropdown
			className='dashboard-drop'
			options={options}
			placeholder="Selecione a empresa"
			search
			selection
			value={empresa}
			onChange={handleChange}
		/>
	), [options, empresa])

	const DropEstabelecimentos = React.useMemo(() =>
	(
		<Dropdown
			className='dashboard-drop'
			options={optionsAux2}
			placeholder="Selecione as organizações"
			search
			selection
			multiple
			clearable
			value={estabelecimento}
			onChange={handleChange2}
		/>
	), [options2, optionsAux2, estabelecimento])

	const handleEdit = (e, plano) => {
		sessionStorage.setItem('empresaId', plano.e_id)
		sessionStorage.setItem('empresaName', plano.e_nome)
		sessionStorage.setItem('estabelecimentoId', plano.es_id)
		sessionStorage.setItem('estabelecimentoModalidade', plano.es_modalidade)
		sessionStorage.setItem('estabelecimentoName', plano.es_nome)
		sessionStorage.setItem('tarefa', plano.t_nome)
		sessionStorage.setItem('atividadeId', plano.t_id)
		sessionStorage.setItem('riscoId', plano.r_id);
		sessionStorage.setItem('riscoName', plano.r_nome)
		sessionStorage.setItem('planoId', plano.id)
		sessionStorage.setItem('dashboardPlano', true)
		const win = window.open("avlrisco", "_blank");
		win.focus();
	}

	const handleEdit2 = (e, plano) => {
		sessionStorage.setItem('empresaId', plano.e_id)
		sessionStorage.setItem('empresaName', plano.e_nome)
		sessionStorage.setItem('planoId', plano.id)
		sessionStorage.setItem('dashboardPlano', true)
		const win = window.open("CadastrarEmpresa", "_blank");
		win.focus();
	}

	async function handleDeletePlano(id) {
		try {
			await api.delete(`plano_acao/${id}`)
			setPlanos(planos.filter(plano => plano.id !== id))
			handleChange2("", { value: estabelecimento })
			aviso('success', 'Plano deletado com sucesso')
		} catch (error) {
			aviso('error', error)
		}
	}

	async function handleDeletePlano2(id) {
		try {
			await api.delete(`plano_acao_geral/${id}`)
			setPlanos(planos.filter(plano => plano.id !== id))
			handleChange2("", { value: estabelecimento })
			aviso('success', 'Plano deletado com sucesso')
		} catch (error) {
			aviso('error', error)
		}
	}

	async function imprimirRelatorio(planos) {

		try {

			let filtro2 = []
			planos.forEach(plano => {
				if (plano.fk_empresa_id === undefined){
					filtro2.push({plano:plano.id})
				}else{
					filtro2.push({plano_geral:plano.id})
				}
					
			})	
			
			setCarregando(true)
			var url = window.location.href.split('://')
			let headers = new Headers();
			headers.append('Content-Type', 'application/json');

			const options = {
				method: "POST",
				headers: headers,
				body: JSON.stringify(filtro2)
			};

			var link=''

			if (url[0] === 'https') {
				link = 'https://'+host.api+'/v1/sheet?token=' + localStorage.getItem('@jmv-token-key')
			}else{
				link = 'http://'+host.api+'/v1/sheet?token=' + localStorage.getItem('@jmv-token-key')
			}
			setCarregando(true)
			await fetch(link, options)
			.then( res => res.blob() )
			.then( blob => {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = "Relatório planos";
				document.body.appendChild(a);
				a.click();
				a.remove();
			});
			setCarregando(false)
	  
		  } catch (error) {
			aviso('error',error)
	  
		  } finally {
			setCarregando(false)
		  }
	}

	return (
		<div>
			<Menu />
			<Loading loading={carregando} message='Carregando...' />
			<div className="basic-content">

				<div className="titulo">
					<h1>Dashboard Gestão de Planos de Ação</h1>
				</div>

				<div className="dashboard-box">

					<div className="dashboard-questions">

						<div className="dashboard-x1">
							<label>Selecione a empresa</label>
							{DropEmpresas}
						</div>

						<div className="dashboard-x1">
							<label>Selecione a organização</label>
							{DropEstabelecimentos}
						</div>

						<div className="dashboard-X">
							<div className="dashboard-x2">

								<label>Intervalo de datas</label>
								<DatePicker
									className="data-picker"
									dateFormat="dd/MM/yyyy"
									locale="pt-BR"
									placeholderText="Data Inicial"
									selected={inicio}
									onChange={(date) => setInicio(date)}
									selectsStart
									startDate={inicio}
									endDate={fim}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									isClearable
								/>
								<DatePicker
									className="data-picker"
									dateFormat="dd/MM/yyyy"
									locale="pt-BR"
									placeholderText="Data Final"
									selected={fim}
									onChange={(date) => setFim(date)}
									selectsEnd
									startDate={inicio}
									endDate={fim}
									minDate={inicio}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									isClearable
								/>

							</div>

						</div>

					</div>

					<Card.Group centered>
						<Card className='dashboard-card' onClick={() => { setFiltro("Todos"); setFiltro3('Todos') }} color="black">
							<Statistic size="small">
								<Statistic.Value><CountUp end={totalP} duration={0.8} /></Statistic.Value>
								<Statistic.Label>Total de planos</Statistic.Label>
							</Statistic>
						</Card>
						<Card className='dashboard-card' onClick={() => { setFiltro("Atrasado"); setFiltro3('Todos') }} color="red">
							<Statistic size="small" color="red">
								<Statistic.Value><CountUp end={pa} duration={0.8} /></Statistic.Value>
								<Statistic.Label>Planos atrasados</Statistic.Label>
							</Statistic>
						</Card>
						<Card className='dashboard-card' onClick={() => { setFiltro("Não iniciado"); setFiltro3('Todos') }} color="yellow">
							<Statistic size="small" color="yellow">
								<Statistic.Value><CountUp end={pni} duration={0.8} /></Statistic.Value>
								<Statistic.Label>Planos não iniciados</Statistic.Label>
							</Statistic>
						</Card>
						<Card className='dashboard-card' onClick={() => { setFiltro("Em andamento"); setFiltro3('Todos') }} color="blue">
							<Statistic size="small" color="blue">
								<Statistic.Value><CountUp end={pea} duration={0.8} /></Statistic.Value>
								<Statistic.Label>Planos em andamento</Statistic.Label>
							</Statistic>
						</Card>
						<Card className='dashboard-card' onClick={() => { setFiltro("Concluído"); setFiltro3('Todos') }} color="green">
							<Statistic size="small" color="green">
								<Statistic.Value><CountUp end={pc} duration={0.8} /></Statistic.Value>
								<Statistic.Label>Planos concluídos</Statistic.Label>
							</Statistic>
						</Card>
					</Card.Group>


					{empresa !== -1 ?
						<div>

							<div className="dashboard-box2">

								<div className="dashboard-chart4">
									<div className="dashboard-chart-title2"><h4>Gráfico planos</h4></div>
									<div>
										{PieChart}
									</div>
								</div>

								<div className="dashboard-chart3">
									<div className="dashboard-stats">
										<div className="dashboard-stats-header">
											<h4>Análise de desempenho</h4>
										</div>
										<div className="dashboard-stats-body">
											<label>Desempenho</label>
											<div className="dashboard-stats-body-1">{((pc / totalP) * 100).toFixed(2)} %</div>
											<label>Planejados</label>
											<div className="dashboard-stats-body-2">{totalP}</div>
											<label>Realizados</label>
											<div className="dashboard-stats-body-3">{pc}</div>
										</div>
									</div>
								</div>

								<div className="dashboard-chart5">
									<div className="dashboard-chart-title2"><h4>Planos concluídos</h4></div>
									{PieChartPrazo}
								</div>


							</div>


							<div className="dashboard-main">

								<div className="dashboard-box3">

									<div className="dashboard-p1">
										<Input fluid size='small' value={name} icon='search' placeholder='Pesquise...' onChange={e => { setName(e.target.value) }} />
									</div>

									<div className="dashboard-P">

										<div className="dashboard-p3">
											<select className="dashboard-select" value={filtro} onChange={e => { setFiltro(e.target.value) }}>
												{optionsSearch.map((nome) => (
													<option key={nome} value={nome}>{nome}</option>
												))}
											</select>
										</div>

										<div className="dashboard-p3">
											<select className="dashboard-select" value={filtro3} onChange={e => { setFiltro3(e.target.value) }}>
												{optionsSearch3.map((nome) => (
													<option key={nome} value={nome}>{nome}</option>
												))}
											</select>
										</div>

										{filtro == "Concluído" ?
											<div className="dashboard-p2">
												<select className="dashboard-select" value={filtro2} onChange={e => { setFiltro2(e.target.value) }}>
													{optionsSearch2.map((nome) => (
														<option key={nome} value={nome}>{nome}</option>
													))}
												</select>
											</div>
											:
											<div className="dashboard-p2"></div>}

									</div>


								</div>
								<div id="scrollableDiv" className="dashboard-ListBox">

									<InfiniteScroll
										dataLength={current.length}
										next={getMoreData}
										hasMore={hasMore}
										scrollableTarget="scrollableDiv"
									>

										{current.map((plano) => (
											<div key={plano.id} className="dashboard-ListItem">
												<ReactTooltip id={toString(plano.id)} place="top" effect="solid"></ReactTooltip>
												<div className="dashboard-item">
													{plano.r_nome !== undefined ?
														<div className="dashboard-item-block">
															<div className="dashboard-item-block-p1">
																<div className="dashboard-item-p1">
																	<a>	<div className={
																		plano.concluido == true ? "Concluido" :
																			plano.iniciado == false && new Date(plano.inicio) >= new Date() ? "NaoIniciado" :
																				plano.iniciado == true && plano.concluido == false && new Date(plano.quando) >= new Date() ? "EmAndamento" : "Atrasado"
																	} />&nbsp;
																		<div className={
																			plano.concluido == true ?
																				new Date(plano.quando) >= new Date(plano.data_concluido) ? "NoPrazo" : "ForaPrazo" : "SemPrazo"
																		} />&nbsp;&nbsp;
																		<b>Ação realizada: </b>{plano.o_que}</a>
																	<a><b>Responsável: </b>{plano.quem}</a>
																</div>
																<div className="dashboard-item-p2">
																	<a><b>Organização: </b>{plano.es_nome}</a>
																	<a><b>Risco: </b>{plano.r_nome}</a>
																	<a><b>Data início: </b>{new Date(plano.inicio).toLocaleDateString('pt-BR')}</a>
																	<a><b>Data fim: </b>{new Date(plano.quando).toLocaleDateString('pt-BR')}</a>
																</div>

																{!Observer && (<div className="dashboard-icons">
																	<i className="dashboard-edit">
																		<FiEdit data-tip="Editar" data-for={toString(plano.id)} onClick={e => handleEdit(e, plano)} />
																	</i>
																	<i className="dashboard-trash">
																		<FiTrash2 data-tip="Excluir" data-for={toString(plano.id)}
																			onClick={() => {
																				const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O PLANO DE AÇÂO SELECIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão')
																				if (r === "excluir") {
																					handleDeletePlano(plano.id);
																				} else {
																					aviso("warning", "Nome incorreto, exclusão cancelada", true)
																				}
																			}} />
																	</i>

																</div>)}
															</div>
															<div className="dashboard-item-block-p2">
																{plano.es_modalidade != "Atividade" ?
																	<a><b>Tarefa: </b>{plano.t_nome}</a>
																	:
																	<a><b>Atividade: </b>{plano.t_nome}</a>
																}
															</div>
														</div>
														:
														<div className="dashboard-item-block">
															<div className="dashboard-item-block-p1">
																<div className="dashboard-item-p1">
																	<a>	<div className={
																		plano.concluido == true ? "Concluido" :
																			plano.iniciado == false && new Date(plano.inicio) >= new Date() ? "NaoIniciado" :
																				plano.iniciado == true && plano.concluido == false && new Date(plano.quando) >= new Date() ? "EmAndamento" : "Atrasado"
																	} />&nbsp;
																		<div className={
																			plano.concluido == true ?
																				new Date(plano.quando) >= new Date(plano.data_concluido) ? "NoPrazo" : "ForaPrazo" : "SemPrazo"
																		} />&nbsp;&nbsp;
																		<b>Ação realizada: </b>{plano.o_que}</a>
																	<a><b>Responsável: </b>{plano.quem}</a>
																</div>
																<div className="dashboard-item-p2">
																	<a><b>Data início: </b>{new Date(plano.inicio).toLocaleDateString('pt-BR')}</a>
																	<a><b>Data fim: </b>{new Date(plano.quando).toLocaleDateString('pt-BR')}</a>
																</div>

																{!Observer && (<div className="dashboard-icons">
																	<i className="dashboard-edit">
																		<FiEdit data-tip="Editar" data-for={toString(plano.id)} onClick={e => handleEdit2(e, plano)} />
																	</i>
																	<i className="dashboard-trash">
																		<FiTrash2 data-tip="Excluir" data-for={toString(plano.id)}
																			onClick={() => {
																				const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O PLANO DE AÇÂO SELECIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão')
																				if (r === "excluir") {
																					handleDeletePlano2(plano.id);
																				} else {
																					aviso("warning", "Nome incorreto, exclusão cancelada", true)
																				}
																			}} />
																	</i>

																</div>)}
															</div>

														</div>
													}
												</div>
											</div>
										))}
									</InfiniteScroll>
								</div>
								<ReactTooltip id="aux" place="top" effect="solid" />
								<div className='dashboard-footer'>
									<div>Planos encontrados: {foundPlanos.length}</div>
									<i><FiPrinter data-for="aux" data-tip="Imprimir relatório"
										onClick={() => imprimirRelatorio(foundPlanos)} /></i>
								</div>

							</div>
						</div>
						:
						null
					}

				</div>

			</div>

		</div>
	);
}