import React from 'react';
import { Context } from '../../../Context/AuthContext';
import api from '../../../services/api';
import Loading from '../../Loading';
import { useState, useEffect } from 'react';
import { FiEdit, FiTrash2, FiPrinter } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import { Checkbox, Input, Dropdown, Statistic, Card } from 'semantic-ui-react'
import CountUp from 'react-countup';

import Chart from "react-google-charts";
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale } from  "react-datepicker";
import br from 'date-fns/locale/pt-BR';


import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Menu } from '../../../global.js';

export default function DashboardRisco() {

	const { aviso, Observer } = React.useContext(Context);

	const [carregando, setCarregando] = useState(false);
	const host = require('../../../config.json')
	const [filtro, setFiltro] = useState('')
	const [filtro2, setFiltro2] = useState('')
	const [filtro3, setFiltro3] = useState('')
	const [filtro4, setFiltro4] = useState('')
	const [name, setName] = useState('')
	const [empresa, setEmpresa] = useState(-1);
	const [estabelecimento, setEstabelecimento] = useState([]);
	const [tipo, setTipo] = useState(false)
	const [options, setOptions] = useState([])
	const [options2, setOptions2] = useState([])
	const [optionsAux2, setOptionsAux2] = useState([])
	const [opt, setOpt] = useState()
	const [opt2, setOpt2] = useState()
	const [opt3, setOpt3] = useState()
	const [opt4, setOpt4] = useState()
	const [chartData, setChartData] = useState([["Risco", "Porcentagem"], ["", ""]])
	const [chartData2, setChartData2] = useState([["Risco", "Porcentagem"], ["", ""]])
	const [chartData3, setChartData3] = useState([["Risco", "Porcentagem"], ["", ""]])
	const [chartData4, setChartData4] = useState([["Risco", "Porcentagem"], ["", ""]])

	const [riscos, setRiscos] = useState([])
	const [foundRiscos, setFoundRiscos] = useState([])

	const [optionsSearch, setOptionsSearch] = useState([])
	const [optionsSearch2, setOptionsSearch2] = useState([])
	const [optionsSearch3, setOptionsSearch3] = useState([])
	const [optionsSearch4, setOptionsSearch4] = useState([])
	
	const [inicio, setInicio] = useState('')
	const [fim, setFim] = useState('')

	const [totalR, setTotalR] = useState(0)

	const [rac, setRac] = useState(0)
	const [rerg, setRerg] = useState(0)
	const [rfi, setRfi] = useState(0)
	const [rqui, setRqui] = useState(0)
	const [rbio, setRbio] = useState(0)

	const [race, setRace] = useState(0)
	const [rina, setRina] = useState(0)
	const [rinc, setRinc] = useState(0)
	const [rna, setRna] = useState(0)

	const [gatilho, _setGatilho] = useState(true)

	registerLocale('pt-BR', br)

	const ref = React.useRef(gatilho);
	const setGatilho = data => {
		ref.current = data;
		_setGatilho(data);
	};

	const [count, setCount] = useState({
		prev: 0,
		next: 10
	})

	const [hasMore, setHasMore] = useState(true);
	const [current, setCurrent] = useState(foundRiscos.slice(count.prev, count.next))

	const getMoreData = () => {

		if (current.length === foundRiscos.length) {
			setHasMore(false);
			return;
		}

		if (current.length > 0) {
			setTimeout(() => {
				setCurrent(current.concat(foundRiscos.slice(count.prev + 10, count.next + 10)))
			}, 500)
			setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
		}

	}


	useEffect(() => {

		function inicial() {

			var keyword = name
			var riscos_filtrados

			if (empresa !== -1 && filtro !== '') {


				if (sessionStorage.getItem("Gatilho") == "true") {
					handleChange2("", { value: estabelecimento })
				}

				sessionStorage.removeItem("Gatilho")

				setFoundRiscos([])
				setCurrent([].slice(0, 10))

				var riscosData
				var riscosF
				var riscosAux
				var riscosAux2

				var ini = new Date(inicio)
				var fi = new Date(fim)

				if((inicio !== null && inicio !== '') && fim < inicio && (fim !== null && fim !== '')){
					aviso("warning", "Data final menor que a inicial ")
					riscosData = riscos
				}else{

					if((inicio !== null && inicio !== '') && (fim == null || fim == '')){
						riscosData = riscos.filter(item => new Date(item.data_criacao) >= ini)
					}else if((fim !== null && fim !== '') && (inicio == null || inicio == '')){
						riscosData = riscos.filter(item => new Date(item.data_criacao) <= fi)
					}else if((inicio !== null && inicio !== '') && (fim !== null && fim !== '')){
						riscosData = riscos.filter(item => (new Date(item.data_criacao) <= fi && new Date(item.data_criacao) >= ini))
					}else{
						riscosData = riscos
					}
					
				}

				setTotalR(riscosData.length)
				updateChart(riscosData)
				updateStats(riscosData)

				if (tipo) {

					if (filtro == "Acidente") {
						riscos_filtrados = riscosData.filter(item => item.tipologia == "Acidente")
					} else if (filtro == "Ergonômico") {
						riscos_filtrados = riscosData.filter(item => item.tipologia == "Ergonômico")
					} else if (filtro == "Físico") {
						riscos_filtrados = riscosData.filter(item => item.tipologia == "Físico")
					} else if (filtro == "Químico") {
						riscos_filtrados = riscosData.filter(item => item.tipologia == "Químico")
					} else if (filtro == "Biológico") {
						riscos_filtrados = riscosData.filter(item => item.tipologia == "Biológico")
					} else {
						riscos_filtrados = riscosData
					}

				} else {

					if (filtro == "Inaceitáveis") {
						riscos_filtrados = riscosData.filter(item => item.resultado > 19)
					} else if (filtro == "Aceitáveis") {
						riscos_filtrados = riscosData.filter(item => item.resultado < 5)
					} else if (filtro == "Incertos") {
						riscos_filtrados = riscosData.filter(item => item.resultado > 11 && item.resultado < 20)
					} else if (filtro == "Todos") {
						riscos_filtrados = riscosData
					} else {
						riscos_filtrados = riscosData.filter(item => item.resultado > 4 && item.resultado < 12)
					}

				}

				updateChart3(riscos_filtrados)

				if(filtro3 === "Todos") {
					riscosAux = riscos_filtrados
				}else if(filtro3 === 'Com risco no PCMSO'){
					riscosAux = riscos_filtrados.filter(item => item.vin_pcmso === "Sim")
				}else{
					riscosAux = riscos_filtrados.filter(item => item.vin_pcmso === "Não" || item.vin_pcmso === "")
				}

				updateChart2(riscosAux)

				if (filtro2 === "Todos") {
					riscosAux2 = riscosAux
				} else if (filtro2 === "Com plano de ação") {
					riscosAux2 = riscosAux.filter(item => item.abrir_plano_acao === "Sim")
				} else {
					riscosAux2 = riscosAux.filter(item => item.abrir_plano_acao === "Não")
				}

				updateChart4(riscosAux2)

				if(filtro4 === "Todos") {
					riscosF = riscosAux2
				}else if(filtro4 === 'Com laudo'){
					riscosF = riscosAux2.filter(item => item.vin_laudo === "Sim")
				}else{
					riscosF = riscosAux2.filter(item => item.vin_laudo === "Não" || item.vin_laudo === "")
				}


				if (keyword !== '') {

					const results = riscosF.filter((risco) => {
						return (risco.nome.toLowerCase().includes(keyword.toLowerCase()) || risco.perigo.toLowerCase().includes(keyword.toLowerCase()) || risco.t_nome.toLowerCase().includes(keyword.toLowerCase()));
					});

					setHasMore(true)
					setFoundRiscos(results);
					setCount({
						prev: 0,
						next: 10
					})
					setTimeout(() => {
						setCurrent(results.slice(0, 10))
					}, 0)


				} else {

					setHasMore(true)
					setFoundRiscos(riscosF);
					setCount({
						prev: 0,
						next: 10
					})
					setTimeout(() => {
						setCurrent(riscosF.slice(0, 10))
					}, 0)


				}

			}

		}

		inicial()


	}, [name, filtro, filtro2, filtro3, filtro4, gatilho, riscos, inicio, fim, tipo])

	useEffect(() => {

		async function inicial() {

			setCarregando(true)

			try {

				if (empresa != -1) {


					setFiltro2("Todos")
					setOptionsSearch2(["Todos", "Com plano de ação", "Sem plano de ação"])
			
					setFiltro3("Todos")
					setOptionsSearch3(["Todos", "Com risco no PCMSO", "Sem risco no PCMSO"])

					setFiltro4("Todos")
					setOptionsSearch4(["Todos", "Com laudo", "Sem laudo"])

					if (tipo) {

						setFiltro("Todos")
						setOptionsSearch(['Todos', 'Acidente', 'Ergonômico', 'Físico', 'Químico', 'Biológico'])

						setOpt({
							is3D: true,
							pieStartAngle: 50,
							backgroundColor: {
								fill: "none",
							},
							legend: { position: "right" },
							chartArea: { width: "95%", height: "85%"},
							slices: {
								0: { color: 'blue' },
								1: { color: '#fabe33' },
								2: { color: 'green' },
								3: { color: 'red' },
								4: { color: 'brown' }
							},
						})

					} else {

						setFiltro("Todos")
						setOptionsSearch(['Todos', 'Inaceitáveis', 'Aceitáveis', 'Incertos', 'Em nível de ação'])

						setOpt({
							is3D: true,
							pieStartAngle: 50,
							backgroundColor: {
								fill: "none",
							},
							legend: { position: "right" },
							chartArea: { width: "95%", height: "85%"},
							slices: {
								0: { color: 'red' },
								1: { color: 'green' },
								2: { color: 'orange' },
								3: { color: 'yellow' }
							},
						})
					}

					setOpt2({
						is3D: true,
						pieStartAngle: 50,
						backgroundColor: {
							fill: "none",
						},
						legend: { position: "right" },
						chartArea: { width: "95%", height: "85%"},
						slices: {
							0: { color: 'blue' },
							1: { color: 'orange' }
						},
					})

					setOpt3({
						is3D: true,
						pieStartAngle: 50,
						backgroundColor: {
							fill: "none",
						},
						legend: { position: "right" },
						chartArea: { width: "95%", height: "85%"},
						slices: {
							0: { color: 'green' },
							1: { color: '#a52a2a' }
						},
					})

					setOpt4({
						is3D: true,
						pieStartAngle: 50,
						backgroundColor: {
							fill: "none",
						},
						legend: { position: "right" },
						chartArea: { width: "95%", height: "85%"},
						slices: {
							0: { color: '#ab2b53' },
							1: { color: '#bf788e' }
						},
					})
				}
			} catch (error) {
				console.log(error)
			} finally {
				setCarregando(false)
			}
		}

		inicial()

	}, [empresa, tipo])

	useEffect(() => {

		async function inicial() {

			setCarregando(true)

			try {

				var op = []
				await Promise.all([
					api.get('empresa')
				]).then(response => {
					response[0].data.resultado.forEach((element, i) => {
						op.push({ key: i, value: element.id, text: element.razao_social })
					});
					setOptions(op)
				})

			} catch (error) {
				console.log(error)
			} finally {
				setCarregando(false)
			}
		}

		inicial()
		window.addEventListener('focus', onfocus);

		// cleanup this component
		return () => {
			window.removeEventListener('focus', onfocus);
		};

	}, [])

	const onfocus = () => {
		setGatilho(!ref.current)
		sessionStorage.setItem("Gatilho", "true")
	}

	const updateChart = (riscos) => {

		if (tipo) {

			var rac2 = riscos.filter(item => item.tipologia == "Acidente").length
			var rerg2 = riscos.filter(item => item.tipologia == "Ergonômico").length
			var rfi2 = riscos.filter(item => item.tipologia == "Físico").length
			var rqui2 = riscos.filter(item => item.tipologia == "Químico").length
			var rbio2 = riscos.filter(item => item.tipologia == "Biológico").length
			setChartData([["Risco", "Porcentagem"], ["Acidente", rac2], ["Ergonômico", rerg2], ["Físico", rfi2], ["Químico", rqui2], ["Biológico", rbio2]])

		} else {

			var rina2 = riscos.filter(item => item.resultado > 19).length
			var race2 = riscos.filter(item => item.resultado < 5).length
			var rinc2 = riscos.filter(item => item.resultado > 11 && item.resultado < 20).length
			var rna2 = riscos.filter(item => item.resultado > 4 && item.resultado < 12).length
			setChartData([["Riscos", "Porcentagem"], ["Riscos inaceitáveis", rina2], ["Riscos aceitáveis", race2], ["Riscos incertos", rinc2], ["Risco em nível de ação", rna2]])

		}

	}

	const updateChart2 = (riscos) => {

		var cplano = riscos.filter(item => item.abrir_plano_acao == "Sim").length
		var splano = riscos.filter(item => item.abrir_plano_acao == "Não").length

		setChartData2([["Riscos", "Porcentagem"], ["Com plano de ação", cplano], ["Sem plano de ação", splano]])

	}

	const updateChart3 = (riscos) => {

		var cpcmso = riscos.filter(item => item.vin_pcmso == "Sim").length
		var spcmso = riscos.filter(item => item.vin_pcmso == "Não").length + riscos.filter(item => item.vin_pcmso == "").length

		setChartData3([["Riscos", "Porcentagem"], ["Com risco no PCMSO", cpcmso], ["Sem risco no PCMSO", spcmso]])

	}

	const updateChart4 = (riscos) => {

		var claudo = riscos.filter(item => item.vin_laudo === "Sim").length
		var slaudo = riscos.filter(item => item.vin_laudo === "Não").length + riscos.filter(item => item.vin_laudo === "").length

		setChartData4([["Riscos", "Porcentagem"], ["Com laudo", claudo], ["Sem Laudo", slaudo]])

	}


	const updateStats = (riscos) => {

		if (tipo) {

			setRac(riscos.filter(item => item.tipologia == "Acidente").length)
			setRerg(riscos.filter(item => item.tipologia == "Ergonômico").length)
			setRfi(riscos.filter(item => item.tipologia == "Físico").length)
			setRqui(riscos.filter(item => item.tipologia == "Químico").length)
			setRbio(riscos.filter(item => item.tipologia == "Biológico").length)

		} else {

			setRina(riscos.filter(item => item.resultado > 19).length)
			setRace(riscos.filter(item => item.resultado < 5).length)
			setRinc(riscos.filter(item => item.resultado > 11 && item.resultado < 20).length)
			setRna(riscos.filter(item => (item.resultado > 4 && item.resultado < 12)).length)
		}

	}

	const handleChange = async (e, { value }) => {

		setCarregando(true)
		setFoundRiscos([])
		setCurrent([].slice(0, 10))
		setTotalR(0)
		setRiscos([])

		setEmpresa(value);
		setEstabelecimento([])

		var op = []

		op.push({ key: -1, value: -1, text: "Todas organizações" })

		const response = await api.get(`estabelecimento?empresa_id=${value}`)

		for (var i=0; i<response.data.resultado.length; i++){
			op.push({ key: i, value: response.data.resultado[i].id, text: response.data.resultado[i].nome })
		}

		setOptions2(op)
		setOptionsAux2(op)

		setCarregando(false)
	}


	const handleChange2 = async (e, { value }) => {

		setEstabelecimento(value);
		setCarregando(true)

		var response = await api.get('dashboardR?empresa_id=' + empresa)
		var risco_empresa

		if(value.includes(-1)){
			setOptionsAux2([{ key: -1, value: -1, text: "Todas organizações" }])
			risco_empresa = response.data.resultado
		}else{
			var top = options2.filter(item => optionsAux2.includes(item))
			if (top.length !== optionsAux2.length){
				setOptionsAux2(options2)
			}
			risco_empresa = response.data.resultado.filter(item => value.includes(item.es_id))
		}

		setTotalR(risco_empresa.length)
		setRiscos(risco_empresa)

		setCarregando(false)

	}

	const handler = function (e) {

		var parts = e.targetID.split("#")[1];

		if (tipo) {

			if (parts === '0') {
				setFiltro("Acidente")
			} else if (parts === '1') {
				setFiltro("Ergonômico")
			} else if (parts === '2') {
				setFiltro("Físico")
			} else if (parts === '3') {
				setFiltro("Químico")
			} else {
				setFiltro("Biológico")
			}

		} else {

			if (parts === '0') {
				setFiltro("Inaceitáveis")
			} else if (parts === '1') {
				setFiltro("Aceitáveis")
			} else if (parts === '2') {
				setFiltro("Incertos")
			} else {
				setFiltro("Em nível de ação")
			}

		}

	};

	const handler2 = function (e) {

		var parts = e.targetID.split("#")[1];

		if (parts === '0') {
			setFiltro2("Com plano de ação")
		} else if (parts === '1') {
			setFiltro2("Sem plano de ação")
		}

	};

	const handler4 = function (e) {

		var parts = e.targetID.split("#")[1];

		if (parts === '0') {
			setFiltro4("Com laudo")
		} else if (parts === '1') {
			setFiltro4("Sem laudo")
		}

	};

	const handler3 = function (e) {

		var parts = e.targetID.split("#")[1];

		if (parts === '0') {
			setFiltro3("Com risco no PCMSO")
		} else if (parts === '1') {
			setFiltro3("Sem risco no PCMSO")
		}

	};

	const PieChart = React.useMemo(() =>
	(
		<Chart
			chartType="PieChart"
			width={'100%'}
			height={'240px'}	
			data={chartData}
			options={opt}
			legendToggle
			chartEvents={[
				{
					eventName: "ready",
					callback: ({ chartWrapper, google }) => {
						const chart = chartWrapper.getChart();
						google.visualization.events.addListener(
							chart,
							"click",
							handler
						);
					}
				}
			]}
		/>
	), [chartData, opt])

	const PieChart5w2h = React.useMemo(() =>
	(
		<Chart
			chartType="PieChart"
			width={'100%'}
			height={'240px'}
			data={chartData2}
			options={opt2}
			legendToggle
			chartEvents={[
				{
					eventName: "ready",
					callback: ({ chartWrapper, google }) => {
						const chart = chartWrapper.getChart();
						google.visualization.events.addListener(
							chart,
							"click",
							handler2
						);
					}
				}
			]}
		/>
	), [chartData2, opt2])

	const PieChartLaudo = React.useMemo(() =>
	(
		<Chart
			chartType="PieChart"
			width={'100%'}
			height={'240px'}
			data={chartData4}
			options={opt4}
			legendToggle
			chartEvents={[
				{
					eventName: "ready",
					callback: ({ chartWrapper, google }) => {
						const chart = chartWrapper.getChart();
						google.visualization.events.addListener(
							chart,
							"click",
							handler4
						);
					}
				}
			]}
		/>
	), [chartData4, opt4])

	const PieChartPcmso = React.useMemo(() =>
	(
		<Chart
			chartType="PieChart"
			width={'100%'}
			height={'240px'}
			data={chartData3}
			options={opt3}
			legendToggle
			chartEvents={[
				{
					eventName: "ready",
					callback: ({ chartWrapper, google }) => {
						const chart = chartWrapper.getChart();
						google.visualization.events.addListener(
							chart,
							"click",
							handler3
						);
					}
				}
			]}
		/>
	), [chartData3, opt3])

	const DropEmpresas = React.useMemo(() =>
	(
		<Dropdown
			className='dashboard-drop'
			options={options}
			placeholder="Selecione a empresa"
			search
			selection
			value={empresa}
			onChange={handleChange}
		/>
	), [options, empresa])

	const DropEstabelecimentos = React.useMemo(() =>
	(
		<Dropdown
			options={optionsAux2}
			placeholder="Selecione as organizações"
			search
			selection
			multiple
			clearable
			value={estabelecimento}
			onChange={handleChange2}
		/>
	), [optionsAux2, options2, estabelecimento, tipo])

	const handleEdit = (e, risco) => {
		sessionStorage.setItem('empresaId', risco.e_id)
		sessionStorage.setItem('empresaName', risco.e_nome)
		sessionStorage.setItem('estabelecimentoId', risco.es_id)
		sessionStorage.setItem('estabelecimentoModalidade', risco.es_modalidade)
		sessionStorage.setItem('estabelecimentoName', risco.es_nome)
		sessionStorage.setItem('tarefa', risco.t_nome)
		sessionStorage.setItem('atividadeId', risco.t_id)
		sessionStorage.setItem('riscoId', risco.id);
		sessionStorage.setItem('riscoName', risco.nome)
		const win = window.open("/cadastrarrisco", "_blank");
		win.focus();
	}

	async function handleDeleteRisco(id) {
		try {
			await api.delete(`risco/${id}`)
			setRiscos(riscos.filter(risco => risco.id !== id))
			handleChange2("", { value: estabelecimento })
			aviso('success', 'Risco deletado com sucesso')
		} catch (error) {
			aviso('error', error)
		}
	}

	async function imprimirRelatorio(riscos) {

		try {

			let filtro2 = []
			riscos.forEach(risco => filtro2.push({risco: risco.id}))

			setCarregando(true)
			var url = window.location.href.split('://')
			let headers = new Headers();
			headers.append('Content-Type', 'application/json');

			const options = {
				method: "POST",
				headers: headers,
				body: JSON.stringify(filtro2)
			};

			var link=''

			if (url[0] === 'https') {
				link = 'https://'+host.api+'/v1/sheet?token=' + localStorage.getItem('@jmv-token-key') +'&tipo=risco'
			}else{
				link = 'http://'+host.api+'/v1/sheet?token=' + localStorage.getItem('@jmv-token-key') +'&tipo=risco'
			}
			setCarregando(true)
			await fetch(link, options)
			.then( res => res.blob() )
			.then( blob => {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = "Relatório riscos";
				document.body.appendChild(a);
				a.click();
				a.remove();
			});
			setCarregando(false)
	  
		  } catch (error) {
			aviso('error',error)
	  
		  } finally {
			setCarregando(false)
		  }
	}

	return (
		<div>
			<Menu />
			<Loading loading={carregando} message='Carregando...' />
			<div className="basic-content">

				<div className="titulo">
					<h1>Dashboard Gestão de Riscos</h1>
				</div>

				<div className="dashboard-box">

					<div className="dashboard-questions">

						<div className="dashboard-q1">
							<label>Selecione a empresa</label>
							{DropEmpresas}
						</div>

						<div className="dashboard-q1">
							<label>Selecione a organização</label>
							{DropEstabelecimentos}
						</div>

						<div className="dashboard-Q">
							<div className="dashboard-q2">

								<label>Intervalo de datas</label>
								<DatePicker
									className="data-picker" 
									dateFormat="dd/MM/yyyy"
									locale="pt-BR"
									placeholderText="Data Inicial"
									selected={inicio}
									onChange={(date) => setInicio(date)}
									selectsStart
									startDate={inicio}
									endDate={fim}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									isClearable
								/>
								<DatePicker
									className="data-picker" 
									dateFormat="dd/MM/yyyy"
									locale="pt-BR"
									placeholderText="Data Final"
									selected={fim}
									onChange={(date) => setFim(date)}
									selectsEnd
									startDate={inicio}
									endDate={fim}
									minDate={inicio}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									isClearable
								/>

							</div>

							<div className="dashboard-q3">
								<label>Organizar riscos por tipologia?</label>
								<Checkbox onChange={(e, value) => setTipo(value.checked)} toggle />
							</div>
						</div>

					</div>
					{!tipo ?
						<Card.Group centered>
							<Card className='dashboard-card2' onClick={() => { setFiltro("Todos"); setFiltro2("Todos") }} color="black">
								<Statistic size="small">
									<Statistic.Value><CountUp end={totalR} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Total de riscos</Statistic.Label>
								</Statistic>
							</Card>
							<Card className='dashboard-card2' onClick={() => { setFiltro("Inaceitáveis") }} color="red">
								<Statistic size="small" color="red">
									<Statistic.Value><CountUp end={rina} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Riscos inaceitáveis</Statistic.Label>
								</Statistic>
							</Card>
							<Card className='dashboard-card2' onClick={() => { setFiltro("Incertos") }} color="orange">
								<Statistic size="small" color="orange">
									<Statistic.Value><CountUp end={rinc} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Riscos incertos</Statistic.Label>
								</Statistic>
							</Card>
							<Card className='dashboard-card2' onClick={() => { setFiltro("Em nível de ação") }} color="yellow">
								<Statistic size="small" color='yellow'>
									<Statistic.Value><CountUp end={rna} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Riscos em nível de ação</Statistic.Label>
								</Statistic>
							</Card>
							<Card className='dashboard-card2' onClick={() => { setFiltro("Aceitáveis") }} color="green">
								<Statistic size="small" color="green">
									<Statistic.Value><CountUp end={race} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Riscos aceitáveis</Statistic.Label>
								</Statistic>
							</Card>
						</Card.Group>
						:
						<Card.Group centered>
							<Card className='dashboard-card' onClick={() => { setFiltro("Todos"); setFiltro2("Todos") }} color="black">
								<Statistic size="small">
									<Statistic.Value><CountUp end={totalR} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Total de riscos</Statistic.Label>
								</Statistic>
							</Card>
							<Card className='dashboard-card' onClick={() => { setFiltro("Acidente") }} color="blue">
								<Statistic size="small" color='blue'>
									<Statistic.Value><CountUp end={rac} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Riscos de acidentes</Statistic.Label>
								</Statistic>
							</Card>
							<Card className='dashboard-card' onClick={() => { setFiltro("Ergonômico") }} color="yellow">
								<Statistic size="small" color='yellow'>
									<Statistic.Value><CountUp end={rerg} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Riscos ergonômicos</Statistic.Label>
								</Statistic>
							</Card>
							<Card className='dashboard-card' onClick={() => { setFiltro("Físico") }} color="green">
								<Statistic size="small" color='green'>
									<Statistic.Value><CountUp end={rfi} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Riscos físicos</Statistic.Label>
								</Statistic>
							</Card>
							<Card className='dashboard-card' onClick={() => { setFiltro("Químico") }} color="red">
								<Statistic size="small" color='red'>
									<Statistic.Value><CountUp end={rqui} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Riscos químicos</Statistic.Label>
								</Statistic>
							</Card>
							<Card className='dashboard-card' onClick={() => { setFiltro("Biológico") }} color="brown">
								<Statistic size="small" color='brown'>
									<Statistic.Value><CountUp end={rbio} duration={0.8} /></Statistic.Value>
									<Statistic.Label>Riscos biológicos</Statistic.Label>
								</Statistic>
							</Card>
						</Card.Group>
					}

					{empresa !== -1 ?
						<div>

							<div className="dashboard-box2">

								<div className="dashboard-chart">
									<div className="dashboard-chart-title"><h4>Gráfico riscos</h4></div>
									<div>{PieChart}</div>	
								</div>

								<div className="dashboard-chart3">
									<div className="dashboard-chart-title"><h4>Gráfico PCMSO</h4></div>
									<div>{PieChartPcmso}</div>	
								</div>

								<div className="dashboard-chart2">
									<div className="dashboard-chart-title"><h4>Gráfico plano de ação</h4></div>
									<div>{PieChart5w2h}</div>
								</div>

								<div className="dashboard-chart2">
									<div className="dashboard-chart-title"><h4>Gráfico Laudo</h4></div>
									<div>{PieChartLaudo}</div>
								</div>

							</div>


							<div className="dashboard-main">

								<div className="dashboard-box3">

									<div className="dashboard-p1">
										<Input fluid size='small' value={name} icon='search' placeholder='Pesquise...' onChange={e => { setName(e.target.value) }} />
									</div>

									<div className="dashboard-P">

										<div className="dashboard-p3">
											<select className="dashboard-select" value={filtro} onChange={e => { setFiltro(e.target.value) }}>
												{optionsSearch.map((nome) => (
													<option key={nome} value={nome}>{nome}</option>
												))}
											</select>
										</div>

										<div className="dashboard-p2">
											<select className="dashboard-select" value={filtro3} onChange={e => { setFiltro3(e.target.value) }}>
												{optionsSearch3.map((nome) => (
													<option key={nome} value={nome}>{nome}</option>
												))}
											</select>
										</div>

										<div className="dashboard-p2">
											<select className="dashboard-select" value={filtro2} onChange={e => { setFiltro2(e.target.value) }}>
												{optionsSearch2.map((nome) => (
													<option key={nome} value={nome}>{nome}</option>
												))}
											</select>
										</div>

										<div className="dashboard-p2">
											<select className="dashboard-select" value={filtro4} onChange={e => { setFiltro4(e.target.value) }}>
												{optionsSearch4.map((nome) => (
													<option key={nome} value={nome}>{nome}</option>
												))}
											</select>
										</div>

									</div>

								</div>
								<div id="scrollableDiv" className="dashboard-ListBox">

									<InfiniteScroll
										dataLength={current.length}
										next={getMoreData}
										hasMore={hasMore}
										scrollableTarget="scrollableDiv"
									>

										{current.map((risco) => (
											<div key={risco.id} className="dashboard-ListItem">
												<ReactTooltip id={toString(risco.id)} place="top" effect="solid"></ReactTooltip>
												<div className="dashboard-item">
													<div className="dashboard-item-block">
														<div className="dashboard-item-block-p1">
															<div className="dashboard-item-p1">
																<a><div data-tip={risco.tipologia} data-for={toString(risco.id)} className={
																	tipo ?
																		risco.tipologia == "Acidente" ?
																		"Acidente": risco.tipologia == "Ergonômico" ?
																		"Ergonomico": risco.tipologia == "Químico" ?
																		"Quimico": risco.tipologia == "Físico" ?
																		"Fisico":"Biologico"
																		:
																		risco.resultado > 19 ?
																		"Quimico" : risco.resultado > 11 ?
																		"Orange" : risco.resultado > 4 ?
																		"Ergonomico" : "Fisico"
																	}
																/>&nbsp;<div data-tip={risco.vin_pcmso == "Sim"? "Com risco no PCMSO":"Sem risco no PCMSO"} data-for={toString(risco.id)} className={
																	risco.vin_pcmso == "Sim"?
																	"ComPcmso":"SemPcmso"
																}/>&nbsp;<div data-tip={risco.abrir_plano_acao == "Sim"? "Com plano de ação":"Sem plano de ação"} data-for={toString(risco.id)} className={
																	risco.abrir_plano_acao == "Sim"?
																	"ComPlano":"SemPlano"
																}/>&nbsp;&nbsp;
																<div data-tip={risco.vin_laudo === "Sim"? "Com laudo":"Sem laudo"} data-for={toString(risco.id)} className={
																	risco.vin_laudo === "Sim"?
																	"ComLaudo":"SemLaudo"
																}/>&nbsp;&nbsp;<b>Risco: </b>{risco.nome}</a>
																<a><b>Perigo: </b>{risco.perigo}</a>
																<a><b>Organização: </b>{risco.es_nome}</a>
																<a><b>Trabalhadores expostos: </b>{risco.numero_trabalhadores}</a>

															</div>
															<div className="dashboard-item-p2">
																<a><b>Severidade: </b>{risco.severidade}</a>
																<a><b>Probabilidade: </b>{risco.probabilidade}</a>
																<a><b>Resultado: </b>{risco.resultado}</a>
																<a><b>PCMSO: </b>{risco.vin_pcmso}</a>
																<a><b>Laudo: </b>{risco.vin_laudo}</a>
																<a><b>5W2H: </b>{risco.abrir_plano_acao}</a>
															</div>

															{!Observer && (<div className="dashboard-icons">
																<i className="dashboard-edit">
																	<FiEdit data-tip="Editar" data-for={toString(risco.id)} onClick={e => handleEdit(e, risco)} />
																</i>
																<i className="dashboard-trash">
																	<FiTrash2 data-tip="Excluir" data-for={toString(risco.id)}
																		onClick={() => {
																			const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O RISCO SELECIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão do risco ' + risco.nome + ' :')
																			if (r === "excluir") {
																				handleDeleteRisco(risco.id);
																			} else {
																				aviso("warning", "Nome incorreto, exclusão cancelada", true)
																			}
																		}} />
																</i>

															</div>)}
														</div>
														<div className="dashboard-item-block-p2">
															{risco.es_modalidade != "Atividade" ?
																<a><b>Tarefa: </b>{risco.t_nome}</a>
																:
																<a><b>Atividade: </b>{risco.t_nome}</a>
															}
														</div>
													</div>

												</div>

											</div>

										))}
									</InfiniteScroll>
								</div>
								<ReactTooltip id="aux" place="top" effect="solid" />
								<div className='dashboard-footer'>
									Riscos encontrados: {foundRiscos.length}
									<i><FiPrinter data-for="aux" data-tip="Imprimir relatório"
										onClick={() => imprimirRelatorio(foundRiscos)} /></i>
								</div>
							</div>
						</div>
						:
						null
					}

				</div>

			</div>

		</div>
	);
}